import { matchPath, Outlet, useLocation } from 'react-router';

import { useAppSelector } from 'dux/app/hooks';

import { shouldShowKustomerLivechat } from 'dux/featureFlags/selectors';
import { useKustomerLivechat } from 'dux/support/hooks';
import { usePageTracker } from 'hooks/usePageTracker';

const originTuples = [
  ['/account/overview', 'Overview'],
  ['/account/settings', 'Settings'],
  ['/account/subscription/home', 'Subscription Home'],
  ['/account/subscription/:category', 'Subscription Edit'],
  ['/account/subscription/:category/snooze', 'Subscription Snooze'],
  ['/account/history', 'Order History'],
  ['/faq', 'FAQ'],
  ['/faq/contact-us', 'Contact Us'],
  ['/contact', 'Contact'],
  ['/the-salon', 'The Salon'],
  ['/ingredients', 'Ingredients'],
] as const;

const KustomerLivechat = ({ children }: { children: React.ReactNode }) => {
  // @ts-expect-error - featureFlags not ported to TS yet
  const showLivechat: boolean = useAppSelector(shouldShowKustomerLivechat);
  const location = useLocation();

  const originTuple = originTuples.find(([path]) => matchPath(path, location.pathname) !== null);
  useKustomerLivechat({
    origin: originTuple?.[1],
    showLivechat: showLivechat && Boolean(originTuple),
  });

  return children;
};

export const RootLayout = () => {
  // Send pageview breadcrumb to API on every pages
  usePageTracker();

  return (
    <KustomerLivechat>
      <Outlet />
    </KustomerLivechat>
  );
};
