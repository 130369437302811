import { createAction } from '@reduxjs/toolkit';

import isEmpty from 'lodash/fp/isEmpty';
import some from 'lodash/some';

import { sha256 } from 'js-sha256';

import { formatPhoneForTracking } from 'constants/countries';
import currencies from 'constants/currencies';
import { feedbackCategories, feedbackSubCategories } from 'constants/feedback';
import { productsCategories, skincareMinisSlugs } from 'constants/products';

import {
  getCartData,
  getCartGift,
  getCatalog,
  getIsReceivingGift,
  getIsSubscribeable,
  getItems,
} from 'dux/checkoutCart/selectors';
import { getAnswersData } from 'dux/consultation/selectors';
import { getUserProfile, getUserPubkey } from 'dux/user/selectors';

import { HEAP_EVENT_PROPERTIES, uiStepToString } from './constants';
import { getGtmCartItems, getGtmOrderItems, getGtmProducts } from './selectors';

const filterGtmProduct = product => ({
  capacity: product.capacity,
  cat: product.cat,
  description: product.description,
  label: product.label,
  productPageUrl: product.productPageUrl,
  price: product.price,
  slug: product.slug,
  tag: product.tag,
  type: product.type,
  usage: product.usage,
  usageB: product.usageB,
  category: product.category,
});

const filterGtmProducts = (items, catalog) => {
  if (catalog.length) {
    return items
      .filter(item => item.category === productsCategories.FORMULA)
      .map(item => {
        const catalogItem = catalog.find(i => i.type === item.type);
        return {
          name: catalogItem.label,
          item_price: catalogItem.unit_price,
          type: catalogItem.type,
          quantity: item.quantity,
          subscription: item?.subscription?.active || false,
          subscriptionFrequency: item?.subscription?.frequency,
        };
      });
  }
  return null;
};

export const filterHeapEventProperties = (data = {}) => {
  const filteredKeys = Object.keys(data)
    .filter(key => {
      return HEAP_EVENT_PROPERTIES.includes(key);
    })
    .reduce((obj, key) => {
      return { ...obj, [key]: data[key] };
    }, {});
  if (!isEmpty(data)) {
    // As of today this tracking function is only called for authed profile but better be safe not creating erroneous data
    filteredKeys.user_type = data.order_count > 0 ? 'customer' : 'account_holder';
    filteredKeys.subscriber_status =
      data.has_active_subscription || data.has_onhold_subscription
        ? 'subscribed'
        : data.has_canceled_subscription
        ? 'was_subscribed'
        : 'never_subscribed';
  }
  return filteredKeys;
};

const preparePayload = (event, payload = {}) => ({ payload: { event, ...payload } });

export const trackGtmEvent = createAction('tracking/GTM/track', preparePayload);
export const trackHeapEvent = createAction('tracking/HEAP/track', preparePayload);

export const identifyHeapEvent = createAction('tracking/HEAP/identify');

export const addHeapEventProperties = createAction('tracking/HEAP/addEventProperties');

export const clearHeapEventProperties = createAction('tracking/HEAP/clearEventProperties');

export const resetHeapIdentity = createAction('tracking/HEAP/resetIdentity');

export const trackSegmentEvent = createAction('tracking/SEGMENT/track', preparePayload);

export const trackIterableEvent = createAction('tracking/ITERABLE/track', preparePayload);

export const trackPubsub = createAction('tracking/PUBSUB/track');

export const trackEvent =
  (event, payload = {}) =>
  dispatch => {
    dispatch(trackHeapEvent(event, payload));
    dispatch(trackGtmEvent(event, payload));
  };

export const identifySegmentEvent = createAction('tracking/SEGMENT/identify');

export const trackCartViewed = cartCategory => (dispatch, getState) => {
  const state = getState();
  const isReceivingGift = getIsReceivingGift(state);
  const isSubscribeable = getIsSubscribeable(state);

  if (isReceivingGift) {
    dispatch(trackHeapEvent('Gift Card Viewed'));
  } else if (isSubscribeable) {
    dispatch(trackHeapEvent('Subscribeable Cart Viewed'));
  } else {
    dispatch(trackHeapEvent('Cart Viewed', { cart_category: cartCategory }));
  }

  dispatch(trackGtmEvent('Cart Viewed', { cart_category: cartCategory }));
  dispatch(trackSegmentEvent('Cart Viewed', { cart_category: cartCategory }));
};

export const trackPaymentStep = step => (dispatch, getState) => {
  const state = getState();
  const isReceivingGift = getIsReceivingGift(state);
  const cartGift = getCartGift(state);
  let event = '';

  if (isReceivingGift) {
    event += 'Giftee ';
  } else if (cartGift) {
    event += 'Gifter ';
  }
  event += uiStepToString[step];
  event += ' Viewed';
  dispatch(trackHeapEvent(event));
};

export const trackBreadcrumb = createAction('tracking/BREADCRUMB/pageView');

export const trackPreselect = createAction('tracking/BREADCRUMB/preselect');

export const trackScroll = scroll_depth => dispatch => {
  dispatch(trackHeapEvent('scrollDepth', { scroll_depth }));
  dispatch(trackGtmEvent('scrollDepth', { scroll_depth }));
};

export const trackHover = payload => dispatch => dispatch(trackHeapEvent('hover', payload));

export const trackSignup = payload => dispatch => {
  dispatch(trackGtmEvent('Account Created', payload));
  dispatch(trackHeapEvent('Account Created', payload));
  dispatch(trackSegmentEvent('Account Created', payload));
  // GA4
  dispatch(trackGtmEvent('create_account', payload));
};

export const trackConsultationStart = () => dispatch => {
  dispatch(trackGtmEvent('Consultation Started'));
  dispatch(trackHeapEvent('Consultation Started'));
};
export const trackConsultationQuestionReached =
  ({ category, questionIndex }) =>
  dispatch => {
    dispatch(trackGtmEvent('Consultation Question Reached', { category, questionIndex }));
    dispatch(trackHeapEvent('Consultation Question Reached', { category, questionIndex }));
  };
export const trackConsultationFinish = payload => dispatch => {
  dispatch(trackGtmEvent('Consultation Completed'));
  dispatch(trackHeapEvent('Consultation Completed'));
  // GA4
  dispatch(
    trackGtmEvent('complete_consultation', {
      ecommerce: {
        category: payload?.category,
      },
    })
  );
};

export const trackPurchaseCompleted = payload => dispatch => {
  dispatch(trackGtmEvent('Purchase Completed', payload));
  dispatch(trackHeapEvent('Purchase Completed', payload));
};
export const trackFirstOrder = (orderPubkey, order_category) => dispatch => {
  dispatch(trackGtmEvent('First Order Placed', { orderPubkey, order_category }));
  dispatch(trackHeapEvent('First Order Placed', { orderPubkey, order_category }));
  dispatch(trackSegmentEvent('First Order Placed'), { orderPubkey, order_category });
};

export const trackGiftPurchase = () => dispatch => {
  dispatch(trackGtmEvent('Gift Purchased'));
  dispatch(trackHeapEvent('Gift Purchased'));
};

export const trackFeedbackConfirmationViewed = payload => dispatch => {
  dispatch(trackGtmEvent('Feedback Confirmation Viewed', payload));
  dispatch(trackHeapEvent('Feedback Confirmation Viewed', payload));
};

export const trackReviewAndRefineAccountViewed = payload => dispatch => {
  dispatch(trackGtmEvent('Review & Refine Account Viewed', payload));
  dispatch(trackHeapEvent('Review & Refine Account Viewed', payload));
};

export const trackReviewAndRefineStarted = payload => dispatch => {
  dispatch(trackGtmEvent('Review and Refine Started', payload));
  dispatch(trackHeapEvent('Review and Refine Started', payload));
};

export const trackReviewAndRefineCompleted = payload => dispatch => {
  dispatch(trackGtmEvent('Review and Refine Completed', payload));
  dispatch(trackHeapEvent('Review and Refine Completed', payload));
};

export const trackMembershipModalViewed = () => dispatch => {
  dispatch(trackGtmEvent('Membership modal viewed'));
  dispatch(trackHeapEvent('Membership modal viewed'));
};

export const trackSubscriptionCancelled = payload => dispatch => {
  dispatch(trackGtmEvent('Subscription Cancelled', payload));
  dispatch(trackHeapEvent('Subscription Cancelled', payload));
  dispatch(trackSegmentEvent('Subscription Cancelled', payload));
  // GA4
  dispatch(
    trackGtmEvent('cancel_subscription', {
      ecommerce: {
        category: payload?.category,
        sub_category: payload?.sub_category,
      },
    })
  );
};

export const trackSubscriptionSnoozed = payload => dispatch => {
  dispatch(trackGtmEvent('Subscription Snoozed', payload));
  dispatch(trackHeapEvent('Subscription Snoozed', payload));
};

const trackSubscriptionStarted =
  ({ location, payload }) =>
  dispatch => {
    dispatch(trackGtmEvent('Subscription Started', { location, ...payload }));
    dispatch(trackHeapEvent('Subscription Started', { location, ...payload }));
    dispatch(trackSegmentEvent('Subscription Started', { location, ...payload }));
  };

export const trackSubscriptionStartedFromCart = payload =>
  trackSubscriptionStarted({ location: 'cart', payload });

export const trackSubscriptionStartedFromAccount = payload =>
  trackSubscriptionStarted({ location: 'account', payload });

export const trackSubscriptionUpdated = payload => dispatch => {
  dispatch(trackGtmEvent('Subscription Updated', payload));
  dispatch(trackHeapEvent('Subscription Updated', payload));
};

export const trackFragranceUpdated = payload => dispatch => {
  dispatch(trackGtmEvent('Fragrance Updated', payload));
  dispatch(trackHeapEvent('Fragrance Updated', payload));
};

export const trackPostPurchaseSurveyCompleted = postPurchaseSurveyEvent => dispatch => {
  dispatch(trackGtmEvent('Post Purchase Survey Completed', postPurchaseSurveyEvent));
  dispatch(trackHeapEvent('Post Purchase Survey Completed', postPurchaseSurveyEvent));
};

export const trackContactFormSubmitted = payload => dispatch => {
  dispatch(trackGtmEvent('Contact Form Submitted', payload));
  dispatch(trackHeapEvent('Contact Form Submitted', payload));
};

export const trackIngredientsSliderPrescriptionViewed = slideNumber => dispatch => {
  dispatch(trackGtmEvent(`Your Formula - Ingredients slide ${slideNumber} viewed`));
  dispatch(trackHeapEvent(`Your Formula - Ingredients slide ${slideNumber} viewed`));
};

export const trackGoalSelectorPrescriptionChanged = goal => dispatch => {
  dispatch(trackGtmEvent(`Scoring slides viewed - ${goal}`));
  dispatch(trackHeapEvent(`Scoring slides viewed - ${goal}`));
};

export const trackUtmSource = payload => dispatch => {
  dispatch(trackSegmentEvent('UTM Source', payload));
};

export const trackRoutineSliderPrescriptionScroll = routineName => dispatch => {
  dispatch(trackGtmEvent(`Routine - ${routineName} Carousel Scrolled`));
  dispatch(trackHeapEvent(`Routine - ${routineName} Carousel Scrolled`));
};
export const trackRoutinePrescriptionLoaded = (event, payload) => dispatch => {
  dispatch(trackGtmEvent(event, payload));
  dispatch(trackHeapEvent(event, payload));
};

export const trackRoutineMiniPDPViewed = payload => dispatch => {
  dispatch(trackGtmEvent('Your Routine - Mini PDP Viewed', payload));
  dispatch(trackHeapEvent('Your Routine - Mini PDP Viewed', payload));
};

export const trackFetchUser = payload => dispatch => {
  dispatch(
    trackGtmEvent('user_logged', {
      userEmail: payload?.email,
      userEmailHashed: payload?.email && sha256(payload.email),
      userPhoneNumber: payload?.phone && formatPhoneForTracking(payload.phone),
      userFirstName: payload?.first_name,
      userId: payload?.pubkey,
      user_id: payload?.pubkey,
      userLastName: payload?.last_name,
      orderCount: payload?.profile?.order_count,
      survey_hair_type: payload?.profile?.diag_hair_type,
      preferredCurrency: payload?.preferred_currency,
      isReturningCustomer: payload?.is_returning_customer,
    })
  );
  dispatch(identifyHeapEvent(payload?.pubkey));
  dispatch(
    addHeapEventProperties({
      'Logged In': 'true',
      ...filterHeapEventProperties(payload?.profile),
    })
  );
  dispatch(identifySegmentEvent(payload?.pubkey));
};

export const trackBufferAnswer = payload => dispatch => {
  dispatch(
    trackGtmEvent('eventGA', {
      survey_name: payload.name,
      survey_question_topic: payload.category,
      survey_question_num: payload.index,
      survey_question_answer: payload.answer,
    })
  );
};

export const trackUserProfile = () => (dispatch, getState) => {
  const profile = getUserProfile(getState());
  dispatch(addHeapEventProperties(filterHeapEventProperties(profile)));
};

export const trackProductViewed =
  ({ category, product, subcategory }) =>
  dispatch =>
    dispatch(
      trackGtmEvent('product_viewed', {
        category,
        ...filterGtmProduct(product),
        subcategory,
      })
    );

export const trackOrderSuccess = order => (dispatch, getState) => {
  const state = getState();
  const userPubkey = getUserPubkey(state);
  const cart = getCartData(state);
  const profile = getUserProfile(state);
  const products = getGtmProducts(state);
  const items = getGtmOrderItems(state);
  const hasSkincareMinisInItems = some(order?.items, item =>
    skincareMinisSlugs.includes(item.variant?.product?.type)
  );
  const skincareMinisValueByCurrency = {
    [currencies.CAD]: 59.02,
    [currencies.USD]: 42.79,
  };

  dispatch(trackUserProfile());

  const gtmData = {
    cart_category: order?.formulaset?.categories?.[0],
    ecommerce: {
      purchase: {
        actionField: {
          id: order.serial_no,
          categories: order?.formulaset?.categories,
          currency: order.currency,
          customerID: userPubkey,
          // order count is incremented just after purchase so new user has order_count=1 here
          customerStatus: profile?.order_count > 1 ? 'Existing' : 'New',
          discountCode: '',
          discountAmount: order.total_price - order.total_amount,
          affiliation: 'Prose-D2C',
          revenue: hasSkincareMinisInItems
            ? skincareMinisValueByCurrency[order.currency] ||
              skincareMinisValueByCurrency[currencies.USD]
            : order.total_price,
          tax: order.total_taxes,
          canSubscribeAtCart: cart.can_subscribe,
          isSubscription: order.is_subscription,
        },
        // Property used to track data for UA (to be deprecated soon)
        products,
        // Property used to track data for GA4 (new syntax)
        items,
      },
    },
  };
  if (order?.formulaset?.categories?.[0] === productsCategories.SKINCARE) {
    dispatch(trackGtmEvent('skincare purchase', gtmData));
  }
  // GA4 event
  dispatch(trackGtmEvent('purchase', gtmData));
  dispatch(trackPaymentStep('success'));
};

export const trackGtmCartChanged = items => (dispatch, getState) => {
  const state = getState();
  const catalog = getCatalog(state);
  const products = filterGtmProducts(items, catalog);

  if (products?.length) {
    dispatch(
      trackGtmEvent('change-cart', {
        ecommerce: {
          cart: {
            products,
          },
        },
      })
    );
  }
};

export const trackCartUpdated = () => (dispatch, getState) => {
  const state = getState();
  const cartData = getCartData(state);
  dispatch(
    trackGtmEvent('cart-updated', {
      revenue: cartData?.payment_from_customer,
      shippingPrice: cartData?.total_shipping,
    })
  );
};

export const trackCartLoaded = () => (dispatch, getState) => {
  const state = getState();
  const items = getItems(state);
  const catalog = getCatalog(state);
  const products = filterGtmProducts(items, catalog);
  const answersData = getAnswersData(state);
  const cartData = getCartData(state);

  if (products?.length) {
    dispatch(
      trackGtmEvent('cart-loaded', {
        ecommerce: {
          cart: {
            products,
          },
        },
        survey_hair_texture: answersData?.diag_hair_texture,
        revenue: cartData?.payment_from_customer,
        shippingPrice: cartData?.total_shipping,
      })
    );
  }
};

export const trackUserSignOut = () => dispatch => {
  dispatch(clearHeapEventProperties());
  dispatch(resetHeapIdentity());
  dispatch(trackHeapEvent('Logged Out'));
};

export const trackCreateAddress = payload => dispatch => {
  dispatch(
    trackGtmEvent('create_address', {
      userAddressHashed: payload?.address1 && sha256(payload.address1),
      userAddressCity: payload?.city,
      userAddressState: payload?.state,
      userAddressZip: payload?.zipcode,
      userAddressCountry: payload?.country,
    })
  );
};

export const trackUpdateAddress = payload => dispatch => {
  dispatch(
    trackGtmEvent('update_address', {
      userAddressHashed: payload?.[0] && sha256(payload[0].address1),
      userAddressCity: payload?.[0]?.city,
      userAddressState: payload?.[0]?.state,
      userAddressZip: payload?.[0]?.zipcode,
      userAddressCountry: payload?.[0]?.country,
    })
  );
};

// GA4 event
export const trackAddShippingInfo = () => (dispatch, getState) => {
  const state = getState();
  const cart = getCartData(state);
  const items = getGtmCartItems(state);

  dispatch(
    trackGtmEvent('add_shipping_info', {
      ecommerce: {
        currency: cart.currency,
        revenue: cart.total_price,
        discountCode: '',
        shipping_tier: 'Ground',
        items,
      },
    })
  );
};

// GA4 event
export const trackBeginCheckout = () => (dispatch, getState) => {
  const state = getState();
  const cart = getCartData(state);
  const items = getGtmCartItems(state);

  dispatch(
    trackGtmEvent('begin_checkout', {
      ecommerce: {
        currency: cart.currency,
        revenue: cart.total_price,
        discountCode: '',
        items,
      },
    })
  );
};

// GA4 event
export const trackAddToCart = () => (dispatch, getState) => {
  const state = getState();
  const cart = getCartData(state);
  const items = getGtmCartItems(state);

  dispatch(
    trackGtmEvent('add_to_cart', {
      ecommerce: {
        currency: cart.currency,
        revenue: cart.total_price,
        discountCode: '',
        items,
      },
    })
  );
};

// GA4 event
export const trackRemoveFromCart = () => (dispatch, getState) => {
  const state = getState();
  const cart = getCartData(state);
  const items = getGtmCartItems(state);

  dispatch(
    trackGtmEvent('remove_from_cart', {
      ecommerce: {
        currency: cart.currency,
        revenue: cart.total_price,
        items,
      },
    })
  );
};

export const trackReviewAndRefineModuleDisplayed = category => dispatch => {
  dispatch(trackGtmEvent('R&R module displayed', { category }));
  dispatch(trackHeapEvent('R&R module displayed', { category }));
};

export const trackOverviewTrialOfferDisplayed =
  ({ category }) =>
  dispatch => {
    dispatch(trackHeapEvent(`Account Overview - ${category} Trial Offer Promo Card - Viewed`));
    dispatch(trackHeapEvent('Account Overview - Any Card - Viewed'));
  };

export const trackOverviewSkincareMinisOfferDisplayed = () => dispatch => {
  dispatch(trackHeapEvent(`Account Overview - SK Minis Promo Card - Viewed`));
  dispatch(trackHeapEvent('Account Overview - Any Card - Viewed'));
};

export const trackFeedbackBannerInNavBar = () => dispatch => {
  dispatch(trackHeapEvent(`Account Nav - R&R Notification - Viewed`));
};

export const trackFeedbackOverviewPromoCard =
  ({ category }) =>
  dispatch => {
    dispatch(trackHeapEvent(`Account Overview - ${category} R&R Card - Viewed`));
  };

export const trackFeedbackBannerInOrderHistory =
  ({ feedbackCategory, feedbackSubCategory }) =>
  dispatch => {
    dispatch(
      trackHeapEvent(`Display of R&R banner on account history page `, {
        feedback_category: feedbackCategory,
        feedback_sub_category: feedbackSubCategory,
      })
    );
  };

export const trackFeedbackLandingPageCard =
  ({ category }) =>
  dispatch => {
    if (category === feedbackCategories.HAIRCARE) {
      dispatch(trackHeapEvent('R&R Landing Page - HC R&R Card - Viewed'));
    } else if (category === feedbackCategories.SKINCARE) {
      dispatch(trackHeapEvent('R&R Landing Page - SK R&R Card - Viewed'));
    } else if (category === feedbackSubCategories.SUPPLEMENTS) {
      dispatch(trackHeapEvent('R&R Landing Page - HC Supplements R&R Card - Viewed'));
    }
  };
