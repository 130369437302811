export const GrowthbookFakeExperimentExperiment = {
  TEST_ID: 'oHUXOZBgQHy7KCSh97Wjbg',
  name: 'GrowthBook Fake Experiment',
};
export const FeedbackAwarenessCtaExperiment = {
  TEST_ID: '8d3bcb79c46428d165b23333ab9ebe39565579a98c4656d1b4c666a005836735',
  name: 'AB Test - "Refine your formula" vs "Provide feedback"',
};
export const PostPurchasePromoLayoutExperiment = {
  TEST_ID: 'f5251fd3efa92646d5a7b45e97567a12d1f46b8a4fae6a389e59f37393efe5ff',
  name: 'Post Purchase Promo Layout AB Test - September 2024',
};
export const SkincareConcernsOrderExperiment = {
  TEST_ID: 'b471cae68150ccf2c376b36318ee95365d36e2ca5c3c2d66f0b1c01dc9886b8b',
  name: 'skincare-concerns-order',
};
export const PostPurchasePopupExperiment = {
  TEST_ID: 'c92d14abe269c5b5efc2bc26c4d88889fc071afe536f78ff017579eedc298b9b',
  name: 'Post purchase popup',
};
export const SubscriptionItemRemovalExperiment = {
  TEST_ID: 'b7e91d330fbea0e29ad20d754fd7de52e398fadf9797b7ea875ec8afb29946f6',
  name: 'Subscription Item Removal',
};
export const PostPurchasePromoDesignPhase2Experiment = {
  TEST_ID: '383d7a50d8a4319f86b673858441de250624f849fd8e568a148557bcce669d8c',
  name: 'Post purchase embedded module P2 ABC Test',
};
export const KeyActionCardExperiment = {
  TEST_ID: 'b2fd43fd11cfd9fe89252b313699dee10e825bed57a0142cc7e1705020440ac5',
  name: 'account_overview_key_action_card_ab_test_april_2025',
};
export const SkincareMinisFirstChargeExperiment = {
  TEST_ID: '7ab6e20bb493c63451d4f971e37794561f30d7d8c1bad1230ed8880281420880',
  name: 'SSS First Charge Date - 28 vs 35 Day Test',
};
export const FrequencyUpdateClarificationExperiment = {
  TEST_ID: '6c9b89c27503721902a6a37f459a5c31190f2d6e6e12f5f38cf17e62a60642a5',
  name: 'Frequency Update Clarification AB Test',
};
export const HomepageEmailCapturePopUpExperiment = {
  TEST_ID: '688787d8ff144c502c7f5cffaafe2cc588d86079f9de88304c26b0cb99ce91c6',
  name: 'Pop-Up Messaging - AB Test - V2',
};
