import cloneDeep from 'lodash/fp/cloneDeep';

import * as BreadcrumbsService from 'Services/BreadcrumbsService';
import * as IterableService from 'Services/IterableService';
import * as TrackingService from 'Services/TrackingService';

import { countriesCode } from 'constants/countries';

import logSentryError from 'utils/logSentry';

import {
  addHeapEventProperties,
  clearHeapEventProperties,
  identifyHeapEvent,
  identifySegmentEvent,
  resetHeapIdentity,
  trackBreadcrumb,
  trackGtmEvent,
  trackHeapEvent,
  trackIterableEvent,
  trackPreselect,
  trackPubsub,
  trackSegmentEvent,
} from 'dux/tracking/actions';
import { getUserGeolocationGuessedCountry } from 'dux/user/selectors';

// wraps the tracker functions to avoid errors if they are not defined
const gtm = payload => {
  if (typeof window?.dataLayer?.push === 'function') {
    // deep clone the object because some pointers are on redux and GTM mutates the payload it receives
    window.dataLayer.push(cloneDeep(payload));
  }
};

const heap = {
  addEventProperties: payload => window?.heap?.addEventProperties?.(payload),
  identify: payload => window?.heap?.identify?.(payload),
  resetIdentity: () => window?.heap?.resetIdentity?.(),
  clearEventProperties: () => window?.heap?.clearEventProperties?.(),
  track: (event, payload) => window?.heap?.track?.(event, payload),
};

const segment = {
  identify: () => {}, // payload => window?.analytics?.identify(payload),
  track: () => {}, // (event, payload) => window?.analytics?.track(event, { properties: payload }),
};

// wrapper for the iterable call to mimic other 3rd parties calls
const iterable = (event, payload) => {
  IterableService.post(event, payload);
};

/*
 * NOTE: follows strategy #3 of organizing middleware listners
 * https://redux-toolkit.js.org/api/createListenerMiddleware#organizing-listeners-in-files
 */
export const addTrackingListeners = startAppListening => {
  startAppListening({
    actionCreator: trackIterableEvent,
    effect: async action => {
      const {
        payload: { event, ...payload },
      } = action;
      iterable(event, payload);
    },
  });

  startAppListening({
    actionCreator: trackGtmEvent,
    effect: async action => {
      gtm(action.payload);
    },
  });
  startAppListening({
    actionCreator: trackHeapEvent,
    effect: async action => {
      const {
        payload: { event, ...payload },
      } = action;
      heap.track(event, payload);
    },
  });
  startAppListening({
    actionCreator: identifyHeapEvent,
    effect: async action => {
      heap.identify(action.payload);
    },
  });
  startAppListening({
    actionCreator: addHeapEventProperties,
    effect: async action => {
      heap.addEventProperties(action.payload);
    },
  });
  startAppListening({
    actionCreator: clearHeapEventProperties,
    effect: async () => {
      heap.clearEventProperties();
    },
  });
  startAppListening({
    actionCreator: resetHeapIdentity,
    effect: async () => {
      heap.resetIdentity();
    },
  });
  startAppListening({
    actionCreator: trackSegmentEvent,
    effect: async (action, { getState }) => {
      const {
        payload: { event, ...payload },
      } = action;
      const nextState = getState();
      if (getUserGeolocationGuessedCountry(nextState) === countriesCode.US) {
        segment.track(event, payload);
      }
    },
  });
  startAppListening({
    actionCreator: identifySegmentEvent,
    effect: async (action, { getState }) => {
      const nextState = getState();
      if (getUserGeolocationGuessedCountry(nextState) === countriesCode.US) {
        segment.identify(action.payload);
      }
    },
  });
  startAppListening({
    actionCreator: trackBreadcrumb,
    effect: async action => {
      BreadcrumbsService.post({
        version: 1,
        type: 'pageview',
        data: action.payload,
      }).catch(error => {
        logSentryError(`[dux/tracking] Breadcrumb pageview`, error);
      });
    },
  });
  startAppListening({
    actionCreator: trackPreselect,
    effect: async action => {
      const { payload } = action;
      BreadcrumbsService.post({
        version: 1,
        type: 'cta_click',
        data: {
          product: payload.productSlug,
          cta_type: 'product_preselect',
          cta_location: payload.locationId,
          product_category: payload.productCategory,
        },
      })
        .then(() => payload.onSuccess?.())
        .catch(error => {
          logSentryError(`[dux/tracking] Breadcrumb cta_click`, error);
        });
    },
  });
  startAppListening({
    actionCreator: trackPubsub,
    effect: async action => {
      const { payload } = action;
      TrackingService.post({
        eventName: payload.eventName,
        data: payload.data,
      }).catch(error => logSentryError(`[dux/tracking] PubSub ${payload.eventName}]`, error));
    },
  });
};
