import { useEffect } from 'react';

import { useLocation } from 'react-router';

import { useAppDispatch } from 'dux/app/hooks';

import { trackBreadcrumb } from 'dux/tracking/actions';

export const usePageTracker = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    dispatch(
      // @ts-expect-error dux not typed yet
      trackBreadcrumb({
        url: `${pathname}${search}`,
      }),
    );
  }, [pathname, search]);
};
